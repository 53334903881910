import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  Typography,
  Checkbox,
  Tooltip,
  Button,
  AddCircleOutlineIcon,
  CheckCircleIcon,
  InfoIcon,
  IconButton,
  AutoFixHighIcon,
  MuiAccordion as Accordion,
  MuiAccordionDetails as AccordionDetails,
  MuiAccordionSummary as AccordionSummary,
  MuiAccordionActions as AccordionActions,
  CircleIcon,
  RemoveCircleIcon,
  Divider,
  DeleteIcon,
  LinearProgress,
  Alert,
  AutoAwesomeIcon,
  CircularProgress,
} from '@cherre-frontend/ui';
import { useForm, useFieldArray } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import InputFields from './InputFields';
import { ToolInputSchema, WebSocketResponse, WebSocketStatus } from '../types';
import { ToolSuggestions } from './ToolSuggestions';
import { createToolInputSchema } from '../utils/formSchemas';

interface ToolsInputProps {
  toolName: string;
  handlePreviewClick: (
    toolName: string,
    toolInputs: Record<string, string>[]
  ) => void;
  handleSuggestionsClick: (
    toolName: string,
    feedback: Record<string, string>,
    toolInputs: Record<string, string>[]
  ) => void;
  description: string;
  selected: boolean;
  toolSchema: ToolInputSchema;
  headerColumns: string[];
  values?: WebSocketResponse | null;
  onSelect?: (selected: boolean) => void;
}

const Tool: React.FC<ToolsInputProps> = ({
  toolName,
  description,
  toolSchema,
  values,
  headerColumns,
  selected,
  handlePreviewClick,
  handleSuggestionsClick,
  onSelect,
}) => {
  const buttonRef = useRef<HTMLButtonElement>(null);

  const [expanded, setExpanded] = useState(false);
  const [loading, setLoading] = useState(false);

  const formSchema = createToolInputSchema(toolSchema);

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      inputs: values?.tool_input || [{}],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'inputs',
  });

  // Handle checkbox change
  const handleCheckboxChange = () => {
    const newSelected = !selected;
    if (onSelect) {
      onSelect(newSelected);
    }
    setExpanded(newSelected);
  };

  const handlePreview = () => {
    setLoading(true);
    handleSubmit(
      (data) => {
        console.log('Preview data', data);
        const inputs = data.inputs ?? [];
        const cleanInputs = inputs.map((input) => {
          if (input['input_column_index']) {
            const index = headerColumns.indexOf(input['input_column_index']);
            input['input_column_index'] =
              index !== -1 ? index.toString() : input['input_column_index'];
          }
          return input;
        });
        handlePreviewClick(toolName, cleanInputs);
      },
      (error) => console.log(error)
    )();
  };
  const handleClearInputs = () => {
    reset({ inputs: [{}] });
  };
  const [showSuggestionsInputs, setShowSuggestionsInputs] = useState(false);
  const handleAISuggestionsClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();
    console.log('Auto fix');
    setExpanded(true);
    setShowSuggestionsInputs((prev) => !prev);
  };

  useEffect(() => {
    switch (values?.status) {
      case WebSocketStatus.SUGGESTION_GENERATED: {
        setLoading(false);
        console.log('Suggested input', values.suggested_input);
        //This is a workaround for the issue of the ingest API returning a nested for position
        const suggested_input = values.suggested_input.map((input) => {
          if (input['position']) {
            input['position'] = input['position'][0];
          }
          return input;
        });
        if (suggested_input) {
          reset({ inputs: suggested_input });
        }
        break;
      }
      case WebSocketStatus.TRANSFORMATION_APPLIED: {
        console.log('Values changed', values);
        setLoading(false);
        break;
      }
      case WebSocketStatus.ERROR: {
        setLoading(false);
        break;
      }
    }
  }, [values, reset]);

  return (
    <Accordion
      elevation={0}
      expanded={expanded}
      onChange={() => setExpanded(!expanded)}
    >
      <AccordionSummary>
        <Box
          display='flex'
          flexDirection='row'
          alignItems='center'
          justifyContent='space-between'
          gap='8px'
          width='100%'
        >
          <Box
            display={'flex'}
            flexDirection={'row'}
            alignItems={'center'}
            justifyContent={'start'}
            gap={'8px'}
          >
            {loading ? (
              <CircularProgress size={20} />
            ) : (
              <Checkbox
                icon={
                  selected ? (
                    <CheckCircleIcon color='success' />
                  ) : expanded ? (
                    <CircleIcon color='primary' />
                  ) : (
                    <AddCircleOutlineIcon />
                  )
                }
                checkedIcon={<CheckCircleIcon color='success' />}
                color='primary'
                checked={selected}
                onChange={handleCheckboxChange}
              />
            )}

            <Typography>{toolSchema.tool_display_name}</Typography>
            {toolSchema.uses_llm && (
              <Tooltip
                title='This Tool uses AI to help transform data'
                arrow
                placement='top'
              >
                <AutoAwesomeIcon color='primary' />
              </Tooltip>
            )}
            <Tooltip title={description} arrow placement='top'>
              <InfoIcon color={'disabled'} />
            </Tooltip>
          </Box>
          {toolSchema.input_suggestion && (
            <>
              <Tooltip title={'Get AI suggestions'}>
                <IconButton
                  ref={buttonRef}
                  color='primary'
                  onClick={handleAISuggestionsClick}
                >
                  <AutoFixHighIcon />
                </IconButton>
              </Tooltip>
              <ToolSuggestions
                open={showSuggestionsInputs && expanded}
                anchorEl={buttonRef.current}
                onClose={() => setShowSuggestionsInputs(false)}
                toolSchema={toolSchema}
                headerColumns={headerColumns}
                onSubmit={(feedback, toolsInput) => {
                  handleSuggestionsClick(toolName, feedback, toolsInput);
                }}
                setLoading={setLoading}
              />
            </>
          )}
        </Box>
        {loading && <LinearProgress />}
      </AccordionSummary>
      <AccordionDetails>
        <Box display='flex' flexDirection='column' gap='10px'>
          {values?.error_message && (
            <Alert severity='error'>{values.error_message}</Alert>
          )}
          {fields.map((field, index) => (
            <Box key={field.id} display='flex' flexDirection='column' mb={2}>
              <InputFields
                inputFields={toolSchema.tool_input_fields}
                headerColumns={headerColumns}
                index={index}
                control={control}
                errors={errors}
              />
              {index > 0 && (
                <Box
                  display='flex'
                  flexDirection='column'
                  alignItems='end'
                  mt={1}
                >
                  <IconButton onClick={() => remove(index)}>
                    <RemoveCircleIcon color='primary' />
                  </IconButton>
                </Box>
              )}
            </Box>
          ))}
          <Box display='flex' flexDirection='row' justifyContent='end'>
            {toolSchema.multiple_columns && (
              <IconButton onClick={() => append({})}>
                <AddCircleOutlineIcon color='primary' />
              </IconButton>
            )}
          </Box>
          <Divider />
        </Box>
      </AccordionDetails>
      <AccordionActions
        disableSpacing
        sx={{
          justifyContent: toolSchema.multiple_columns ? 'space-between' : 'end',
        }}
      >
        {toolSchema.multiple_columns && (
          <Button
            variant='text'
            startIcon={<DeleteIcon />}
            onClick={handleClearInputs}
          >
            Clear inputs
          </Button>
        )}
        <Button variant='outlined' onClick={handlePreview} disabled={selected}>
          Preview
        </Button>
      </AccordionActions>
    </Accordion>
  );
};

Tool.displayName = 'Tool';
export default Tool;
