import React from 'react';
import {
  Card,
  CardActions,
  CardContent,
  Button,
  Popper,
  Box,
  IconButton,
  RemoveCircleIcon,
  AddCircleOutlineIcon,
  CardHeader,
  CloseIcon,
} from '@cherre-frontend/ui';
import { useFieldArray, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { createAiSuggestionInputSchema } from '../utils/formSchemas';
import { ToolInputSchema } from '../types';
import InputFields from './InputFields';

interface ToolSuggestionsProps {
  open: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  anchorEl: HTMLElement | null;
  onClose: () => void;
  onSubmit: (
    feedback: Record<string, string>,
    toolInput: Record<string, string>[]
  ) => void;
  toolSchema: ToolInputSchema;
  headerColumns: string[];
  showFeedback?: boolean;
}

export const ToolSuggestions: React.FC<ToolSuggestionsProps> = ({
  open,
  anchorEl,
  onClose,
  onSubmit,
  toolSchema,
  headerColumns,
  setLoading,
}) => {
  const showFeedback = toolSchema?.feedback;

  // Create default values for inputs with proper structure
  const createDefaultInputValues = () => {
    if (!toolSchema?.llm_input_fields) {
      return [{}];
    }

    return [
      Object.keys(toolSchema.llm_input_fields).reduce((prev, cur) => {
        prev[cur] = '';
        return prev;
      }, {} as Record<string, string>),
    ];
  };

  // Create default values for feedback with proper structure
  const createDefaultFeedbackValues = () => {
    if (!toolSchema?.feedback_fields) {
      return {};
    }

    return Object.keys(toolSchema.feedback_fields).reduce((prev, cur) => {
      prev[cur] = '';
      return prev;
    }, {} as Record<string, string>);
  };

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    resolver: zodResolver(createAiSuggestionInputSchema(toolSchema)),
    defaultValues: {
      inputs: createDefaultInputValues(),
      feedback: showFeedback ? createDefaultFeedbackValues() : undefined,
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'inputs',
  });

  const handleGetSuggestions = () => {
    handleSubmit(
      (data) => {
        setLoading(true);
        const feedback = data.feedback ?? {};
        const inputs = data.inputs ?? [];
        const cleanInputs = inputs.map((input) => {
          if (input['input_column_index']) {
            const index = headerColumns.indexOf(input['input_column_index']);
            input['input_column_index'] =
              index !== -1 ? index.toString() : input['input_column_index'];
          }
          return input;
        });
        onSubmit(feedback, cleanInputs);
        onClose();
      },
      (errors) => {
        console.log('Error in suggestion request', errors);
      }
    )();
  };

  // Create a properly structured empty input object
  const handleAppend = () => {
    const emptyInput = Object.keys(toolSchema?.llm_input_fields || {}).reduce(
      (prev, cur) => {
        prev[cur] = '';
        return prev;
      },
      {} as Record<string, string>
    );

    append(emptyInput);
  };

  return (
    <Popper
      open={open}
      anchorEl={anchorEl}
      sx={{ zIndex: 10 }}
      placement='bottom-end'
      modifiers={[
        {
          name: 'preventOverflow',
          enabled: true,
          options: {
            altAxis: true,
            altBoundary: true,
            tether: true,
            rootBoundary: 'document',
            padding: 8,
          },
        },
        {
          name: 'arrow',
          enabled: true,
          options: {
            element: anchorEl,
          },
        },
      ]}
    >
      <Card
        elevation={10}
        sx={{ width: 400 }}
        onClick={(e) => e.stopPropagation()}
      >
        <CardHeader
          title='AI Assistance Configuration'
          action={
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          }
        />
        <CardContent sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          {fields.map((field, index) => (
            <Box key={field.id} display='flex' flexDirection='column' mb={2}>
              <InputFields
                inputFields={toolSchema.llm_input_fields}
                headerColumns={headerColumns}
                index={index}
                control={control}
                errors={errors}
              />
              {index > 0 && (
                <Box
                  display='flex'
                  flexDirection='column'
                  alignItems='end'
                  mt={1}
                >
                  <IconButton onClick={() => remove(index)}>
                    <RemoveCircleIcon color='primary' />
                  </IconButton>
                </Box>
              )}
            </Box>
          ))}
          <Box display='flex' flexDirection='row' justifyContent='end'>
            {toolSchema.multiple_columns &&
              Object.keys(toolSchema.llm_input_fields ?? {}).length > 0 && (
                <IconButton onClick={handleAppend}>
                  <AddCircleOutlineIcon color='primary' />
                </IconButton>
              )}
          </Box>
          {showFeedback && (
            <InputFields
              formFieldPrefix='feedback'
              inputFields={toolSchema.feedback_fields}
              control={control}
              errors={errors}
            />
          )}
        </CardContent>
        <CardActions sx={{ justifyContent: 'end' }}>
          <Button size='small' onClick={handleGetSuggestions}>
            Get AI suggestions
          </Button>
        </CardActions>
      </Card>
    </Popper>
  );
};
