import React, { useMemo, useRef } from 'react';
import {
  Table,
  useTable,
  useTheme,
  Box,
  LinearProgress,
  Typography,
} from '@cherre-frontend/ui';

interface DataTableProps {
  data?: Record<string, string>[];
  loading?: boolean;
  title?: string;
}

export const DataTable: React.FC<DataTableProps> = ({
  data,
  loading,
  title,
}) => {
  const theme = useTheme();
  const tableContainerRef = useRef<HTMLDivElement>(null);

  const columns = useMemo(() => {
    return data?.[0]
      ? Object.keys(data[0]).map((key) => {
          const columnWidth = key.length * 6 + 100;
          return {
            accessorKey: key,
            header: key,
            size: columnWidth,
            grow: true,
          };
        })
      : [];
  }, [data]);

  const table = useTable({
    defaultDisplayColumn: { enableResizing: true },
    skeletonBaseObj: {
      A: 'value',
      B: 'value',
      C: 'value',
      D: 'value',
      E: 'value',
      F: 'value',
      G: 'value',
      H: 'value',
      I: 'value',
      J: 'value',
    },
    data,
    columns,
    layoutMode: 'grid',
    skeletonRowCount: 10,
    muiTableProps: {
      sx: {
        '& .MuiTableRow-root td': {
          backgroundColor: '#fff',
        },
        '& .MuiTableSortLabel-icon': {
          color: 'white !important',
        },
      },
    },
    displayColumnDefOptions: {
      'mrt-row-numbers': {
        size: 40,
        minSize: 40,
        maxSize: 50,
        Header: '',
        muiTableHeadCellProps: {
          sx: {
            backgroundColor: theme.palette.grey[500],
            borderRight: `0.5px white solid`,
            '& .MuiDivider-root': {
              borderColor: 'transparent',
            },
          },
        },
        muiTableBodyCellProps: {
          align: 'center',
          sx: {
            fontWeight: 600,
            backgroundColor: 'white',
            color: 'black',
            alignItems: 'center',
            borderRight: `0.5px ${theme.palette.grey[400]} solid`,
            boxShadow: 'none',
          },
        },
      },
    },
    enableTopToolbar: false,
    enableBottomToolbar: false,
    enableTableFooter: false,
    enableColumnResizing: true,
    enableColumnPinning: true,
    enableRowNumbers: true,
    enablePagination: false,
    enableDensityToggle: false,
    enableRowActions: false,
    enableRowSelection: false,
    enableColumnActions: true,
    enableColumnFilters: true,
    enableStickyHeader: true,
    columnResizeMode: 'onChange',
    muiTablePaperProps: {
      sx: {
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        flex: 1,
        border: `0.5px ${theme.palette.grey[400]} solid`,
        maxHeight: 'calc(100vh - 480px)',
      },
      elevation: 0,
    },
    muiTableContainerProps: {
      sx: {
        flex: 1,
        height: '100%',
      },
      ref: tableContainerRef,
    },
    initialState: {
      columnPinning: { left: ['mrt-row-numbers'], right: [] },
    },
    muiTableHeadCellProps: {
      sx: {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: theme.palette.grey[500],
        borderRight: `0.5px white solid`,
        borderBottom: `0.5px white solid`,
        color: 'white',
        height: '36px',
      },
    },
    muiTableBodyCellProps: {
      sx: {
        backgroundColor: 'white',
      },
    },
    muiTableBodyRowProps: {
      sx: {
        height: '36px',
        backgroundColor: 'white',
      },
    },
    renderColumnActionsMenuItems: ({ internalColumnMenuItems }) => {
      return [...internalColumnMenuItems.splice(4, 4)];
    },
    enableSorting: true,
    localization: {
      sortedByColumnAsc: 'Click to sort in descending order.',
      sortedByColumnDesc: 'Click to sort in ascending order.',
      sortByColumnAsc: 'Click to sort in ascending order.',
      sortByColumnDesc: 'Click to sort in descending order.',
    },
    state: {
      showSkeletons: loading,
    },
    manualSorting: false,
    sortDescFirst: false,
    enableSortingRemoval: false,
    renderEmptyRowsFallback: () => (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <Typography variant='subtitle1'>No rows found.</Typography>
      </Box>
    ),
  });

  return (
    <Box
      style={{
        display: 'flex',
        flex: 1,
        width: '100%',
        flexDirection: 'column',
      }}
    >
      {title && (
        <Typography variant='h6' sx={{ mb: 2 }}>
          {title}
        </Typography>
      )}
      <Table table={table} />
      <Box sx={{ width: '100%' }}>{loading && <LinearProgress />}</Box>
    </Box>
  );
};
