export enum WebSocketStatus {
  FILE_ID_SUBMITTED = 'file_id_submitted',
  FILE_RETREIVED = 'file_retrieved',
  REQUEST_SUGGESTION = 'request_suggestion',
  SUGGESTION_GENERATED = 'suggestion_generated',
  APPLY_TOOL_TRANSFORM = 'apply_tool_transform',
  TRANSFORMATION_APPLIED = 'transformation_applied',
  GET_TOOL_LIST = 'get_tool_list',
  TOOL_LIST_RETREIVED = 'tool_list_retrieved',
  ERROR = 'error',
}

export interface ToolField {
  type: string;
  required: boolean;
  description: string;
  value?: string | string[] | { [key: string]: string };
}

export interface ToolInputSchema {
  category: string;
  tool_display_name: string;
  requires_input: boolean;
  uses_llm: boolean;
  input_suggestion: boolean;
  feedback: boolean;
  multiple_columns?: boolean;
  tool_input_fields?: {
    [key: string]: ToolField;
  };
  llm_input_fields?: {
    [key: string]: ToolField;
  };
  feedback_fields?: {
    [key: string]: ToolField;
  };
}

export interface Tool {
  tool_name: string;
  description: string;
  schema: ToolInputSchema;
}

export interface TransformationInfo {
  selected_tool_name: string;
  df_before_header?: Record<string, string>[];
  header?: string[];
  df_after_header?: Record<string, string>[];
  tool_input: Record<string, string>[];
}

export interface WebSocketMessage {
  status: string;
  file_id?: string;
  tool_name?: string;
  df_before_header?: Record<string, string>[];
  header?: string[];
  df_after_header?: Record<string, string>[];
  tool_input?: Record<string, string>[];
  explanation?: Record<string, string>;
  error_message?: string;
  feedback?: {
    assistant?: string;
    instructions?: string;
  };
}

export interface WebSocketResponse extends TransformationInfo {
  status: string;
  file_id: string;
  df_before_header: Record<string, string>[];
  header: string[];
  df_after_header: Record<string, string>[];
  selected_tool_name: string;
  explanation: Record<string, string>;
  error_message: string;
  tool_list?: Tool[];
  suggested_input: Record<string, string>[];
}
