import React from 'react';
import {
  TextField,
  MuiAutocomplete as Autocomplete,
  Box,
  Chip,
} from '@cherre-frontend/ui';
import { ToolField } from '../types';
import { isNumber } from 'lodash';
import {
  Control,
  Controller,
  FieldErrors,
  FieldValues,
  Path,
  PathValue,
} from 'react-hook-form';

interface InputFieldsProps<T extends FieldValues> {
  inputFields?: Record<string, ToolField>;
  headerColumns?: string[];
  index?: number;
  formFieldPrefix?: string;
  control: Control<T>;
  errors: FieldErrors<T>;
}

const InputFields = <T extends FieldValues>({
  inputFields,
  headerColumns,
  index,
  formFieldPrefix = 'inputs',
  control,
  errors,
}: InputFieldsProps<T>) => {
  if (!inputFields) {
    return null;
  }
  const fields = Object.entries(inputFields);

  const getFieldConfig = (key: string, field: ToolField) => {
    const fieldName = (
      isNumber(index)
        ? `${formFieldPrefix}.${index}.${key}`
        : `${formFieldPrefix}.${key}`
    ) as Path<T>;
    const fieldError = isNumber(index)
      ? errors?.[formFieldPrefix]?.[index]?.[key]?.message
      : errors?.[formFieldPrefix]?.[key]?.message;
    const options =
      (key === 'input_column_index' || key === 'input_column_indices'
        ? headerColumns
        : (field.value as string[])) ?? [];
    const defaultValue = '' as PathValue<T, Path<T>>;

    return { fieldName, fieldError, defaultValue, options };
  };

  return (
    <Box display='flex' flexDirection='column' gap={2}>
      {fields.map(([key, field]) => {
        const { fieldName, fieldError, defaultValue, options } = getFieldConfig(
          key,
          field
        );
        // Column index dropdown for selecting from header columns
        if (key === 'input_column_index') {
          return (
            <Controller
              key={fieldName}
              name={fieldName}
              control={control}
              defaultValue={defaultValue}
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  options={options}
                  value={value || null}
                  onChange={(_, newValue) => onChange(newValue)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={field.description}
                      variant='outlined'
                      size='small'
                      error={!!fieldError}
                      helperText={(fieldError as string) ?? ' '}
                      required={field.required}
                    />
                  )}
                />
              )}
            />
          );
        }

        // Select dropdown for predefined values
        else if (
          field.type === 'string' &&
          options &&
          Array.isArray(field.value)
        ) {
          return (
            <Controller
              key={fieldName}
              name={fieldName}
              control={control}
              defaultValue={defaultValue}
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  options={options}
                  value={value || null}
                  onChange={(_, newValue) => onChange(newValue ?? '')}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={field.description}
                      variant='outlined'
                      size='small'
                      error={!!fieldError}
                      helperText={(fieldError as string) ?? ' '}
                      required={field.required}
                    />
                  )}
                />
              )}
            />
          );
        }
        // Select dropdown for predefined values
        else if (field.type === 'array' || key === 'input_column_indices') {
          const defaultValue = [] as PathValue<T, Path<T>>;
          return (
            <Controller
              key={fieldName}
              name={fieldName}
              control={control}
              defaultValue={defaultValue}
              render={({ field: { onChange, value } }) => {
                return (
                  <Autocomplete
                    multiple
                    freeSolo
                    //This is a workaround for the issue of the ingest API returning a nested for position
                    value={value ?? []}
                    options={options}
                    onChange={(_, newValue) => onChange(newValue ?? [])}
                    renderTags={(value: readonly string[], getTagProps) => {
                      return value.map((option: string, index: number) => {
                        const { key, ...tagProps } = getTagProps({ index });
                        return (
                          <Chip
                            variant='outlined'
                            label={option}
                            key={key}
                            {...tagProps}
                          />
                        );
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={field.description}
                        variant='outlined'
                        size='small'
                        error={!!fieldError}
                        helperText={(fieldError as string) ?? ' '}
                        required={field.required}
                      />
                    )}
                  />
                );
              }}
            />
          );
        } else {
          // Regular text input
          return (
            <Controller
              key={fieldName}
              name={fieldName}
              control={control}
              defaultValue={defaultValue}
              render={({ field: { onChange, value, onBlur } }) => (
                <TextField
                  fullWidth
                  label={field.description || key}
                  variant='outlined'
                  size='small'
                  value={value ?? ''}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={!!fieldError}
                  helperText={(fieldError as string) ?? ' '}
                  required={field.required}
                  placeholder={field.description}
                  type={field.type === 'number' ? 'number' : 'text'}
                />
              )}
            />
          );
        }
      })}
    </Box>
  );
};

InputFields.displayName = 'InputFields';
export default InputFields;
