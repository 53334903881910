import { useState } from 'react';
import useWebSocket from 'react-use-websocket';
import {
  WebSocketMessage,
  WebSocketResponse,
  WebSocketStatus,
  Tool,
  TransformationInfo,
} from '../types';

interface UseTransformWebSocketProps {
  webSocketUrl: string;
  fileId: string;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setCategories: React.Dispatch<
    React.SetStateAction<Record<string, Tool[]> | undefined>
  >;
  setCurrentTransformation: (transformation: TransformationInfo) => void;
  explanations: Record<string, string> | undefined;
  setExplanations: React.Dispatch<
    React.SetStateAction<Record<string, string> | undefined>
  >;
  setBeforeHeaderData: React.Dispatch<
    React.SetStateAction<Record<string, string>[]>
  >;
  setHeaderData: React.Dispatch<React.SetStateAction<Record<string, string>[]>>;
  setAfterHeaderData: React.Dispatch<
    React.SetStateAction<Record<string, string>[]>
  >;
}

export const useTransformWebSocket = ({
  webSocketUrl,
  fileId,
  setLoading: setPageLoading,
  setCategories,
  setCurrentTransformation,
  explanations,
  setExplanations,
  setBeforeHeaderData,
  setHeaderData,
  setAfterHeaderData,
}: UseTransformWebSocketProps) => {
  const [loading, setLoading] = useState(false);

  const handleFileRetrieved = (response: WebSocketResponse) => {
    setCategories(
      Object.values(response?.tool_list ?? []).reduce((result, current) => {
        result[current.schema.category] = result[current.schema.category] || [];
        result[current.schema.category].push(current);
        return result;
      }, {} as Record<string, Tool[]>)
    );
    setBeforeHeaderData(response.df_before_header ?? []);
    setHeaderData(
      response?.header
        ? [
            response?.header.reduce((result, col, index) => {
              result[index] = col;
              return result;
            }, {}),
          ]
        : []
    );
    setAfterHeaderData(response.df_after_header ?? []);
    setPageLoading(false);
    console.log('Tool list:', response.tool_list);
  };
  const handleTransformationApplied = (response: WebSocketResponse) => {
    setCurrentTransformation({
      selected_tool_name: response?.selected_tool_name ?? '',
      df_before_header: response?.df_before_header ?? [],
      header: response?.header ?? [],
      df_after_header: response?.df_after_header ?? [],
      tool_input: response?.tool_input ?? [],
    });
    setExplanations(response.explanation);
    setBeforeHeaderData(response.df_before_header ?? []);
    setHeaderData(
      response?.header
        ? [
            response?.header.reduce((result, col, index) => {
              result[index] = col;
              return result;
            }, {}),
          ]
        : []
    );
    setAfterHeaderData(response.df_after_header ?? []);
  };
  const handleSuggestionGenerated = (response: WebSocketResponse) => {
    setExplanations(response.explanation);
  };
  const handleWebSocketError = (error: Error) => {
    console.error('WebSocket error:', error);
    setLoading(false);
    setPageLoading(false);
  };
  const { sendJsonMessage, lastJsonMessage } = useWebSocket(webSocketUrl, {
    onOpen: () => {
      submitFileId(fileId);
    },
    onMessage: (event) => {
      const message = JSON.parse(event.data) as WebSocketResponse;

      switch (message.status) {
        case WebSocketStatus.FILE_RETREIVED:
          handleFileRetrieved(message);
          break;
        case WebSocketStatus.TRANSFORMATION_APPLIED:
          setLoading(false);
          handleTransformationApplied(message);
          break;
        case WebSocketStatus.SUGGESTION_GENERATED:
          handleSuggestionGenerated(message);
          break;
        case WebSocketStatus.ERROR:
          handleWebSocketError(message as unknown as Error);
      }
    },
    onError: (event) => {
      handleWebSocketError(event as unknown as Error);
    },
  });

  const submitFileId = (fileId: string) => {
    sendJsonMessage({
      status: WebSocketStatus.FILE_ID_SUBMITTED,
      file_id: fileId,
    });
  };

  const requestSuggestion = (
    toolName: string,
    currentState: Partial<WebSocketMessage>
  ) => {
    const message = {
      status: WebSocketStatus.REQUEST_SUGGESTION,
      file_id: fileId,
      selected_tool_name: toolName,
      ...currentState,
      explanation: explanations ?? {},
    };
    console.log('suggestion ws request', message);
    sendJsonMessage(message);
  };

  const applyToolTransform = (
    toolName: string,
    currentState: Partial<WebSocketMessage>
  ) => {
    setLoading(true);
    const message = {
      status: WebSocketStatus.APPLY_TOOL_TRANSFORM,
      file_id: fileId,
      selected_tool_name: toolName,
      ...currentState,
      explanation: explanations ?? {},
    };
    console.log('transform ws request', message);
    sendJsonMessage(message);
  };

  return {
    loading,
    lastJsonMessage,
    requestSuggestion,
    applyToolTransform,
  };
};
