import * as z from 'zod';
import { ToolInputSchema } from '../types';

export const createToolInputSchema = (toolSchema: ToolInputSchema) => {
  if (!toolSchema.tool_input_fields) {
    return z.object({
      inputs: z.array(z.object({})),
    });
  }

  const fieldsSchemas: Record<string, z.ZodType> = {};

  Object.entries(toolSchema.tool_input_fields).forEach(([key, field]) => {
    let fieldSchema: z.ZodType = z.string().or(z.number());

    if (field.type === 'array') {
      fieldSchema = z.array(z.string().or(z.number()));
    }
    if (!field.required) {
      fieldSchema = fieldSchema.optional();
    }

    fieldsSchemas[key] = fieldSchema;
  });

  return z.object({
    inputs: z.array(z.object(fieldsSchemas)),
  });
};

export const createAiSuggestionInputSchema = (toolSchema: ToolInputSchema) => {
  // Create schema for LLM input fields
  const llmFieldsSchemas: Record<string, z.ZodType> = {};
  if (toolSchema.llm_input_fields) {
    Object.entries(toolSchema.llm_input_fields).forEach(([key, field]) => {
      let fieldSchema: z.ZodType = z.string().or(z.number());

      if (field.type === 'array') {
        fieldSchema = z.array(z.string().or(z.number()));
      }
      if (!field.required) {
        fieldSchema = fieldSchema.optional();
      }
      llmFieldsSchemas[key] = fieldSchema;
    });
  }

  // Create schema for feedback fields
  const feedbackFieldsSchemas: Record<string, z.ZodType> = {};
  if (toolSchema.feedback_fields) {
    Object.entries(toolSchema.feedback_fields).forEach(([key, field]) => {
      let fieldSchema: z.ZodType = z.string();

      if (field.required) {
        fieldSchema = (fieldSchema as z.ZodString).min(1, {
          message: 'This field is required',
        });
      } else {
        fieldSchema = fieldSchema.optional();
      }

      feedbackFieldsSchemas[key] = fieldSchema;
    });
  }

  // Create the main schema structure
  return z.object({
    inputs: z.array(z.object(llmFieldsSchemas)),
    feedback: z.object(feedbackFieldsSchemas).optional(),
  });
};
