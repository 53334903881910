import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { IngestionFlowStepServiceProps } from '../../IngestionFlow';
import { IngestTransformFileStep } from './IngestTransformFileStep';

const getWebSocketUrl = () => {
  const parsedUrl = new URL(window.location.origin);
  const isDevelopment = process.env.NODE_ENV === 'development';
  const protocol =
    parsedUrl.protocol !== 'https:' && isDevelopment ? 'ws:' : 'wss:';
  const wsUrl = `${protocol}//${parsedUrl.host}`;
  return wsUrl;
};
export const IngestTransformFileStepService: React.FC<
  IngestionFlowStepServiceProps
> = ({ flowState, setFlowState, nextStep }) => {
  if (!flowState.fileId) {
    throw new Error('Need to upload a file before transforming');
  }

  const onTranformingFileContinue = useCallback(
    async (
      toolSteps: {
        selected_tool_name: string;
        tool_input: Record<string, string>[];
      }[]
    ) => {
      if (!flowState.fileId) {
        throw new Error('No file ID found');
      }

      setFlowState((prev) => ({
        ...prev,
        toolSteps,
      }));

      nextStep?.();
    },
    [flowState.fileId]
  );
  const domOwner = useSelector((state) => state.user.profile.value.domOwner);
  const webSocketUrl = `${getWebSocketUrl()}/api/ingest/transform?owner=${domOwner}`;

  return (
    <IngestTransformFileStep
      onClickSubmit={onTranformingFileContinue}
      fileId={flowState.fileId}
      webSocketUrl={webSocketUrl}
    />
  );
};
