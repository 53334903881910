import React, { useState } from 'react';
import {
  Box,
  PageContent,
  Typography,
  MuiAccordion as Accordion,
  MuiAccordionSummary as AccordionSummary,
  MuiAccordionDetails as AccordionDetails,
  RemoveCircleIcon,
  AddCircleIcon,
  Button,
  Divider,
  EditIcon,
  CircularProgress,
  useTheme,
  Grid2 as Grid,
} from '@cherre-frontend/ui';
import { HelpBox } from '../../..';
import Tool from './components/Tool';
import { DataTable } from './components/DataTable';
import { useTransformWebSocket } from './hooks/useTransformWebSocket';
import { Tool as ToolType, TransformationInfo } from './types';
import { startCase } from 'lodash';

export type IngestFileTransformStepProps = {
  onClickSubmit: (
    toolSteps: {
      selected_tool_name: string;
      tool_input: Record<string, string>[];
    }[]
  ) => Promise<void>;
  fileId: string;
  webSocketUrl: string;
};

export const IngestTransformFileStep: React.FC<
  IngestFileTransformStepProps
> = ({ fileId, onClickSubmit, webSocketUrl }) => {
  const theme = useTheme();
  const [expanded, setExpanded] = useState<string>();
  const [currentTransformation, setCurrentTransformation] =
    useState<TransformationInfo>();
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState<Record<string, ToolType[]>>();
  const [explanations, setExplanations] = useState<Record<string, string>>();
  const [appliedTransformations, setAppliedTransformations] = useState<
    TransformationInfo[]
  >([]);
  const [beforeHeaderData, setBeforeHeaderData] = useState<
    Record<string, string>[]
  >([]);
  const [headerData, setHeaderData] = useState<Record<string, string>[]>([]);
  const [afterHeaderData, setAfterHeaderData] = useState<
    Record<string, string>[]
  >([]);

  const {
    loading: wsLoading,
    lastJsonMessage,
    requestSuggestion,
    applyToolTransform,
  } = useTransformWebSocket({
    webSocketUrl,
    fileId,
    setCategories,
    setLoading,
    setCurrentTransformation,
    explanations,
    setExplanations,
    setBeforeHeaderData,
    setHeaderData,
    setAfterHeaderData,
  });

  const handleApplyTransformation = () => {
    currentTransformation &&
      setAppliedTransformations((prev) => [
        ...prev,
        { ...currentTransformation },
      ]);
  };

  const handleCancelTransformation = () => {
    setCurrentTransformation(
      appliedTransformations[appliedTransformations.length - 1]
    );
  };

  const isPreview =
    appliedTransformations[appliedTransformations.length - 1]
      ?.selected_tool_name !== currentTransformation?.selected_tool_name;

  const handleSubmitClick = () => {
    const toolSteps = appliedTransformations.map((transformation) => ({
      selected_tool_name: transformation.selected_tool_name,
      tool_input: transformation.tool_input,
    }));
    onClickSubmit(toolSteps);
  };
  const getHeaderColumns = (category: string) => {
    if (category === 'before_header' && beforeHeaderData?.[0]) {
      return Object.keys(beforeHeaderData[0]);
    } else if (category === 'header' && headerData[0]) {
      return Object.keys(headerData[0]);
    } else if (afterHeaderData[0]) {
      return Object.keys(afterHeaderData[0]);
    }
    return [];
  };
  return (
    <>
      <HelpBox
        title='Review the parsed output'
        subtitle={
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Typography>
              Clean up your file by using the tools we have available below. You
              can also highlight cells in your file and describe what data
              transformations you'd like to see. If a tool isn't working or if
              you'd like to request a new one to be built, please let us know.
            </Typography>
          </Box>
        }
        helpText='Provide Feedback on Tool(s)'
        submitText='Next'
        onClickHelp={() => {
          throw new Error('Not implemented');
        }}
        onClickSubmit={handleSubmitClick}
      />
      <Grid
        container
        spacing={2}
        sx={{
          justifyContent: 'space-between',
        }}
      >
        <Grid md={9}>
          <PageContent
            sx={{
              width: '100%',
              height: 'calc(100vh - 60px)',
              display: 'flex',
              flexDirection: 'column',
              gap: '20px',
              overflow: 'auto',
              backgroundColor: isPreview
                ? theme.palette.error.light
                : theme.palette.background.paper,
            }}
          >
            {loading ? (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <>
                <Box
                  display='flex'
                  flexDirection='row'
                  gap='16px'
                  justifyContent={'space-between'}
                >
                  <Typography variant='h6'>
                    {isPreview ? 'Preview' : 'Your File'}
                  </Typography>
                  {isPreview && (
                    <Box>
                      <Button onClick={handleCancelTransformation}>
                        Cancel
                      </Button>
                      <Button
                        variant='contained'
                        startIcon={<EditIcon />}
                        onClick={handleApplyTransformation}
                      >
                        Apply Transformations
                      </Button>
                    </Box>
                  )}
                </Box>
                <DataTable
                  title='Before Header'
                  data={beforeHeaderData}
                  loading={wsLoading}
                />
                <DataTable
                  title='Header'
                  data={headerData}
                  loading={wsLoading}
                />
                <DataTable
                  title='After Header'
                  data={afterHeaderData}
                  loading={wsLoading}
                />
              </>
            )}
          </PageContent>
        </Grid>
        <Grid md={3}>
          <PageContent
            sx={{
              width: '100%',
              display: 'flex',
              height: 'calc(100vh - 60px)',
              flexDirection: 'column',
              paddingBottom: '16px',
              overflow: 'auto',
            }}
          >
            <Box
              display='flex'
              flexDirection='column'
              gap='16px'
              justifyContent='space-between'
              sx={{ height: '100%' }}
            >
              <Box display={'flex'} flexDirection={'row'} gap={'8px'}>
                <EditIcon color='disabled' />
                <Typography variant='h6'>Available Tools</Typography>
              </Box>
              <Typography variant='body1'>
                Highlight cells and describe the action you'd like to take to
                see recommended tools to use.
              </Typography>
              <Box
                display='flex'
                flexDirection='column'
                gap='16px'
                sx={{
                  flex: 1,
                }}
              >
                {categories
                  ? Object.entries(categories).map(
                      ([category, tools], index) => {
                        const categoryDisplayName = startCase(category);
                        const headerColumns = getHeaderColumns(category);

                        return (
                          <Accordion
                            elevation={0}
                            expanded={expanded === category}
                            onChange={(_, open) =>
                              setExpanded(open ? category : undefined)
                            }
                            key={category}
                          >
                            <AccordionSummary
                              sx={{ backgroundColor: theme.palette.grey[300] }}
                            >
                              <Box
                                display='flex'
                                flexDirection='row'
                                alignItems='center'
                                gap='8px'
                              >
                                {expanded === category ? (
                                  <RemoveCircleIcon color='primary' />
                                ) : (
                                  <AddCircleIcon color='primary' />
                                )}
                                {`${index + 1}. ${categoryDisplayName}`}
                              </Box>
                            </AccordionSummary>
                            <AccordionDetails>
                              {Object.values(tools).map((tool) => (
                                <Tool
                                  key={tool.tool_name}
                                  toolName={tool.tool_name}
                                  toolSchema={tool.schema}
                                  description={tool.description}
                                  selected={Boolean(
                                    appliedTransformations.find(
                                      (transformation) =>
                                        transformation.selected_tool_name ===
                                        tool.tool_name
                                    )
                                  )}
                                  headerColumns={headerColumns}
                                  handlePreviewClick={(
                                    toolName,
                                    toolInputs
                                  ) => {
                                    applyToolTransform(toolName, {
                                      df_before_header: beforeHeaderData,
                                      header: Object.values(headerData[0]),
                                      df_after_header: afterHeaderData,
                                      explanation: explanations,
                                      tool_input: toolInputs,
                                    });
                                  }}
                                  handleSuggestionsClick={(
                                    toolName,
                                    feedback,
                                    toolInputs
                                  ) =>
                                    requestSuggestion(toolName, {
                                      df_before_header: beforeHeaderData,
                                      header: Object.values(headerData[0]),
                                      df_after_header: afterHeaderData,
                                      explanation: explanations,
                                      tool_input: toolInputs,
                                      feedback: feedback,
                                    })
                                  }
                                  values={
                                    lastJsonMessage?.selected_tool_name ===
                                    tool.tool_name
                                      ? lastJsonMessage
                                      : null
                                  }
                                />
                              ))}
                            </AccordionDetails>
                          </Accordion>
                        );
                      }
                    )
                  : null}
              </Box>
              <Divider />
              <Box display='flex' justifyContent='end'>
                <Button startIcon={<AddCircleIcon />}>Request new tool</Button>
              </Box>
            </Box>
          </PageContent>
        </Grid>
      </Grid>
    </>
  );
};
